/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import Main from "../views/settings/Main";
import {MenuItems} from "../views/AppHeader";

MenuItems.push({
    iconCls: 'el-icon-setting',
    title: 'Настройки',
    to: {name: "settings"}
});

router.addRoutes([
    {
        path: '/settings',
        component: Main,
        children: [
            {
                path: '', //default route
                name: 'settings',
                component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Basics'),
            },
            {
                path: 'informers',
                name: 'settings-informers',
                component: () => import(/* webpackChunkName: "settings" */ '../views/informer/List'),
            },
        ]
    }
]);
