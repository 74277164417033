/**
 * The global proxy to make http requests
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import axios from "axios";

let baseUrl = process.env.VUE_APP_API_BASEURL;

axios.defaults.baseURL = baseUrl;


/**
 * Helper to get errors from axios err
 * */
export function parseResponseErrorMessage(error) {
    let message = 'Ошибка связи';

    //handle validation errors here
    if (error.response) {
        if (error.response.status === 422) {
            // get first error
            if (error.response.data[0] && error.response.data[0]['message']) {
                return  error.response.data[0]['message'];
            }
        }
        // get message key if persist
        if (error.response.data && error.response.data['message']) {
            return  error.response.data['message'];
        }
    }

    return message;
}