import Vue from 'vue'
import App from './App.vue'

import router from './router'
import './router/login'
import './router/order'
import './router/product'
import './router/showcase'
import './router/counterparty'
import './router/courier'
import './router/vendor'
import './router/report'
import './router/settings'

import './plugins/element'
import './plugins/axios'
import './plugins/moment'
import './plugins/validator'

import './utils/filters'

import './scss/main.scss'
import Formatter from "@/utils/Formatter";


Vue.config.productionTip = false;
Vue.prototype.$Formatter = Formatter;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
