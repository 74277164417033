/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
export default [
    {
        path: 'calendar',
        name: 'order-calendar',
        component: () => import(/* webpackChunkName: "orderCalendar" */ '../views/orderCalendar/Calendar'),
    },
    {
        path: 'calendar/:day',
        name: 'order-daylist',
        props: true,
        component: () => import(/* webpackChunkName: "orderCalendar" */ '../views/orderCalendar/DateView'),
        children: [
            {
                path: 'shipment-nomenclature',
                name: 'orderCalendar-shipmentNomenclature',
                props: true,
                component: () => import(/* webpackChunkName: "orderCalendar" */ '../views/orderCalendar/ShipmentNomenclature')
            },
            {
                path: 'orders',
                name: 'orderCalendar-orders',
                props: true,
                component: () => import(/* webpackChunkName: "orderCalendar" */ '../views/orderCalendar/OrderList')
            }
        ]
    },
]