import Vue from "vue";
import Formatter from "./Formatter";

/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

// add global filters
Vue.filter("date", function (value, format) {
    return Formatter.asDate(value, format);
});

Vue.filter("datetime", function (value, format) {
    return Formatter.asDateTime(value, format);
});

Vue.filter("time", function (value, format) {
    return Formatter.asTime(value, format);
});

Vue.filter("currency", function (value, nullDisplay) {
    return Formatter.asCurrency(value, nullDisplay);
});

Vue.filter("percent", function (value, nullDisplay) {
    return Formatter.asPercent(value, nullDisplay);
});