/**
 * Apply router setup
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import User from "../views/auth/User";
import Login from "../views/auth/Login";

router.addRoutes([
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) f`or this route
        // which is lazy-loaded when the route is visited.
        component: Login
    }
]);


// add hook
router.beforeEach((to, from, next) => {
    if (User.isAuth()) {
        // prevent loop: redirect to home
        if (to.name == "login") {
            next(from);
        } else {
            next();
        }
    } else {
        // not at login route
        if (to.name != "login") {
            // try to autologin
            User.tryAutoLogin()
                .then(() => {
                    next();
                })
                .catch(() => {
                    next({name: 'login'});
                });
        } else {
            next();
        }
    }
});