<script>
/**
 * Info about logged user
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import User from "./User";
import SessionList from "@/views/auth/SessionList";

export default {
  name: "UserInfo",
  components: {SessionList},
  data() {
    return {
      model: User,
      isVisible: false
    }
  },

  methods: {
    onClick: function () {
      this.model.logout()
          .finally(() => {
            this.$router.push({name: 'login'});
          });
    }
  }
}
</script>


<template>
  <div>
    <div class="userinfo" @click="isVisible=true">
      <el-icon class="el-icon-user-solid"></el-icon>&nbsp;
      <span>{{ model.fullname }}</span>
      <el-icon class="el-icon-caret-bottom"></el-icon>
    </div>

    <el-drawer direction="rtl" :visible.sync="isVisible" append-to-body destroy-on-close>
      <template slot="title">
        <h3>
          <el-icon class="el-icon-user"></el-icon>
          {{ model.fullname }}
        </h3>
      </template>
      <div class="content">
        <p>
          <el-icon class="el-icon-message"></el-icon>
          {{ model.email }}
        </p>
        <el-button @click="onClick" icon="el-icon-switch-button" type="danger">Выход</el-button>
        <hr>
        <session-list></session-list>
      </div>
    </el-drawer>
  </div>
</template>


<style lang="scss" scoped>
.userinfo {
  cursor: pointer;
}

.content {
  max-width: 420px;
  padding: 0 1rem;
}
</style>