/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import {MenuItems} from "@/views/AppHeader";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-user',
    title: 'Контрагенты',
    to: {name: 'counterparty-list'}
});

router.addRoutes([
    {
        path: '/counterparty/list',
        component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/Main'),
        children: [
            {
                path: '',
                name: 'counterparty-list',
                component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/List'),
            },
            {
                path: '/counterparty/create',
                name: 'counterparty-create',
                component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/Form')
            },
            {
                path: '/counterparty/:id',
                component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/card/Main'),
                props: true,
                children: [
                    {
                        path: '',
                        alias: 'edit',
                        name: 'counterparty-edit',
                        props: true,
                        component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/Form'),
                    },
                    {
                        path: 'shipping-addresses',
                        name: 'counterparty-shipping-addresses',
                        props: true,
                        component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/card/ShippingAddresses'),
                    },
                    {
                        path: 'group-list',
                        name: 'counterparty-has-group-list',
                        props: true,
                        component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/card/GroupList'),
                    },
                    {
                        path: 'order-history',
                        name: 'counterparty-order-history',
                        props: true,
                        component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/card/OrderHistory'),
                    }
                ]
            },


            {
                path: '/counterparty-group/list',
                name: 'counterparty-group-list',
                component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/group/List')
            },
            {
                path: '/counterparty-group/create',
                name: 'counterparty-group-create',
                component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/group/Form')
            },
            {
                path: '/counterparty-group/:id',
                name: 'counterparty-group-edit',
                props: true,
                component: () => import(/* webpackChunkName: "counterparty" */ '../views/counterparty/group/Form')
            }
        ]
    }
]);
