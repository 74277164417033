/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import {MenuItems} from "../views/AppHeader";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-data-line',
    title: 'Отчетность',
    to: {name: 'report'}
});


const routes = [
    {
        path: '/report',
        name: 'report',
        component: () => import(/* webpackChunkName: "reports" */ '../views/report/Main'),
        children: [
            {
                path: 'product',
                name: 'report-product',
                component: () => import(/* webpackChunkName: "report" */ '../views/report/product/Main')
            },
            {
                path: 'debt',
                name: 'report-debt',
                component: () => import(/* webpackChunkName: "report" */ '../views/report/debt/Main')
            },
            {
                path: 'payment',
                name: 'report-payment',
                component: () => import(/* webpackChunkName: "report" */ '../views/report/payment/Main')
            }
        ]
    },
];


router.addRoutes(routes);