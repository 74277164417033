/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import {MenuItems} from "../views/AppHeader";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-truck',
    title: 'Курьеры',
    to: {name: 'courier-list'}
});

router.addRoutes([
    {
        path: '/courier/list',
        component: () => import(/* webpackChunkName: "courier" */ '../views/courier/Main'),
        children: [
            {
                path: '',
                name: 'courier-list',
                component: () => import(/* webpackChunkName: "courier" */ '../views/courier/List'),
            },
            {
                path: '/courier/create',
                name: 'courier-create',
                component: () => import(/* webpackChunkName: "courier" */ '../views/courier/Form')
            },
            {
                path: '/courier/:id',
                component: () => import(/* webpackChunkName: "courier" */ '../views/courier/card/Main'),
                props: true,
                children: [
                    {
                        path: 'edit',
                        name: 'courier-edit',
                        props: true,
                        component: () => import(/* webpackChunkName: "courier" */ '../views/courier/Form'),
                    },
                    {
                        path: 'cashbox',
                        name: 'courier-cashbox',
                        props: true,
                        component: () => import(/* webpackChunkName: "courier" */ '../views/courier/cashbox/Main'),
                    },
                ]
            }
        ]
    }
]);
