<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import UserInfo from "./auth/UserInfo";

    export let MenuItems = [];

    export default {
        name: "Header",

        components: {
            UserInfo
        },

        data() {
            return {
                title: process.env.VUE_APP_TITLE,
                items: MenuItems,
                isVisible: false
            }
        }
    }
</script>


<template>
    <div class="app-header">
        <el-header>
            <div class="app-title" @click="isVisible=true">
                <img class="app-title__logo" :alt="`${title} logo`" src="../assets/logo-min.png">
<!--                <h1 class="app-title__text">{{title}}</h1>-->
                <el-icon class="el-icon-caret-bottom"></el-icon>
            </div>
            <user-info></user-info>
        </el-header>


        <el-drawer :visible.sync="isVisible"
                   append-to-body
                   direction="ltr">

            <div class="app-title" slot="title">
                <img class="app-title__logo" :alt="`${title} logo`" src="../assets/logo-min.png">
<!--                <h1 class="app-title__text">{{title}}</h1>-->
            </div>

            <el-menu align="left"
                     :router="true">
                <router-link v-for="(item, index) in items"
                             class="el-menu-item"
                             tag="li"
                             @click.native="isVisible=false"
                             :key="index"
                             :to="item.to">
                    <i :class="item.iconCls"></i>
                    <span>{{item.title}}</span>
                </router-link>
            </el-menu>
        </el-drawer>
    </div>
</template>


<style lang="scss" scoped>
    @import "../scss/element";
    @import "../scss/mixins/fonts";

    .app-header {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 999;
    }

    header {
        border-bottom: 1px solid #eeefff;
        display: flex;
        justify-content: space-between;
        line-height: 60px;

        .app-title {
            display: flex;
            cursor: pointer;
            align-items: center;
            flex: 0 0 auto;

            &__logo {
                margin: 0 10px 0 0;
                height: 40px;
            }

            &__text {
                margin: 0;
                flex-grow: 1;
                font-size: 2rem;
                font-weight: 500;
            }
        }
    }

    .el-drawer__header {
        .app-title {
            flex-grow: 1;
        }
    }


    .el-menu-item {
        font-size: 16px;
        color: $--color-primary;

        [class^="el-icon-"] {
            font-size: 24px;
            color: $--color-primary;
        }

        &.router-link-active {
            cursor: default;
            @include font-roboto-thin();
            color: $--color-info;

            [class^="el-icon-"] {
                color: $--color-info;
            }
        }
    }

</style>