<script>
    export default {
        name: 'app',

    }
</script>

<template>
    <router-view></router-view>
</template>


<style scoped lang="scss">

</style>
