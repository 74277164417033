/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import {MenuItems} from "../views/AppHeader";
import Main from "../views/showcase/Main";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-s-shop',
    title: 'Витрина',
    to: {name: 'categories'}
});

router.addRoutes([
    {
        path: '/showcase',
        component: Main,//() => () => import(/* webpackChunkName: "showcase" */ '../views/showcase/Main'),
        children: [
            {
                path: 'tree',
                name: 'showcase-tree',
                component: () => import(/* webpackChunkName: "showcase" */ '../views/showcase/Tree'),
            },
            {
                path: '',
                name: 'categories',
                component: () => import(/* webpackChunkName: "showcase" */ '../views/category/List'),
            },
            {
                path: '/category/create',
                name: 'category-create',
                component: () => import(/* webpackChunkName: "showcase" */ '../views/category/CreateForm'),
            },
            {
                path: '/category/:id',
                component: () => import(/* webpackChunkName: "showcase" */ '../views/category/card/Main'),
                props: true,
                children: [
                    {
                        path: '',
                        name: 'category-basic',
                        component: () => import(/* webpackChunkName: "showcase" */ '../views/category/card/Basic')
                    },
                    {
                        path: 'images',
                        name: 'category-images',
                        component: () => import(/* webpackChunkName: "showcase" */ '../views/category/card/Images')
                    },
                    {
                        path: 'tree',
                        name: 'category-tree',
                        component: () => import(/* webpackChunkName: "showcase" */ '../views/category/card/Tree')
                    },
                ]
            }
        ]
    }
]);
