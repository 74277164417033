/**
 * The Global Formatter
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import moment from "moment";

const Formatter = {
    locale: 'ru-RU',

    firstDayOfWeek: 1,

    nullDisplay: '-',
    currencySymbol: 'р.',
    thousandCurrencySymbol: 'т.р.',

    dateDisplayFormat: 'dd.MM.yyyy',
    dateSubmitFormat: 'yyyy-MM-dd',
    dateTimeDisplayFormat: 'dd.MM.yyyy hh:mm:ss',
    dateTimeSubmitFormat: 'yyyy-MM-dd hh:mm:ss',


    /**
     * options for Intl NumberFormat
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
     */
    numberFormat: {
        currency: 'RUB',
        currencyDisplay: 'symbol',
        unit: 'byte',
        unitDisplay: 'short',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },


    /**
     * Replace tel number to a[href=tel:] compatible filterString
     * */
    asTelLink: function (str) {
        return `tel:${str.replace(/[\s()-]/g, '')}`;
    },


    /**
     * Format date
     * @param str
     * @param format string - The moment format
     * */
    asDate: function (str, format) {
        return moment(str).format(format || "L");
    },


    /**
     * Format date with time
     * @param str
     * @param format string - The moment format
     * */
    asDateTime: function (str, format) {
        return moment(str).format(format || "LLL");
    },


    /**
     * Format time
     * @param str
     * @param format string - The moment format
     * */
    asTime: function (str, format) {
        return moment(str).format(format || "LT");
    },


    /**
     * The date from now
     * */
    asRelativeTime(str, withoutSuffix) {
        return moment(str).fromNow(withoutSuffix);
    },


    /**
     * Use Intl.NumberFormat with options extend
     * @param value string - The filterString to be format
     * @param options object|null - The extra options to be passed to Intl.numberFormat constructor
     * */
    asDecimal: function (value, options) {
        options = {
            style: 'decimal',
            ...this.numberFormat,
            ...options
        };

        return new Intl.NumberFormat(this.locale, options).format(value);
    },


    /**
     * Use Intl.NumberFormat with options extend
     * @param value string - The filterString to be format
     * @param options object|null - The extra options to be passed to Intl.numberFormat constructor
     * */
    asCurrency: function (value, options) {
        options = {
            style: 'currency',
            ...this.numberFormat,
            ...options
        };

        return new Intl.NumberFormat(this.locale, options).format(value);
    },


    /**
     * Price format with thousands short
     * */
    asPrice: function (value, nullDisplay) {
        let number = parseFloat(value),
            symbol = this.currencySymbol;

        if (typeof number != 'number' || isNaN(number)) {
            return nullDisplay !== null ? nullDisplay : this.nullDisplay;
        }

        if (number >= 1000) {
            number = this.asDecimal(number / 1000);
            symbol = this.thousandCurrencySymbol;
        }

        return this.formatString('{0} {1}', number, symbol);
    },


    /**
     * Use Intl.NumberFormat with options extend
     * @param value string - The filterString to be format
     * @param options object|null - The extra options to be passed to Intl.numberFormat constructor
     * */
    asPercent: function (value, options) {
        options = {
            style: 'percent',
            ...options
        };

        return value > 0 ? new Intl.NumberFormat(this.locale, options).format(value / 100) : this.nullDisplay;
    },


    /**
     * Use Intl.NumberFormat with options extend
     * @param value string - The filterString to be format
     * @param options object|null - The extra options to be passed to Intl.numberFormat constructor
     *
     * @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
     * @see https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
     * * */
    asUnit: function (value, options) {
        options = {
            style: 'unit',
            ...options
        };

        return new Intl.NumberFormat(this.locale, options).format(value);
    },


    /**
     * Helper for unit kilogram
     * @see https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
     * */
    asWeight(value) {
        return this.asUnit(value, {
            unit: 'kilogram'
        });
    },


    /**
     * String formatting with replace placeholders
     * */
    formatString: function (str) {
        var args = Array.prototype.slice.call(arguments, 1);
        return str.replace(/{(\d+)}/g, function (match, number) {
            return (typeof args[number] != 'undefined') ? args[number] : match;
        });
    },


    /**
     * Helper to determine if day is before today
     * @param date Date
     * @return boolean
     * */
    isBeforeToday(date) {
        return moment(date).isBefore(moment(), "day");
    },


    /**
     * Helper to determine if day is after today
     * @param date Date
     * @return boolean
     * */
    isAfterToday(date) {
        return moment(date).isBefore(moment(), "day");
    },

    /**
     * Format to submit date format to be send to server
     * */
    toSubmitDate(date) {
        return moment(date).format("YYYY-MM-DD");
    }
};

export default Formatter;