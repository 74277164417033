/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import Main from "../views/product/Main";
import {MenuItems} from "../views/AppHeader";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-goods',
    title: 'Продукты',
    to: {name: 'products'}
});

router.addRoutes([
    {
        path: '/products',
        component: Main,
        children: [
            {
                path: '',
                name: 'products',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/List'),
            },
            {
                path: '/product/create',
                name: 'product-create',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/CreateForm'),
            },
            {
                path: '/product/:id',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/card/Main'),
                props: true,
                children: [
                    {
                        path: '',
                        name:'product-basic',
                        component: () => import(/* webpackChunkName: "products" */ '../views/product/card/Basic')
                    },
                    {
                        path: 'images',
                        name:'product-images',
                        component: () => import(/* webpackChunkName: "products" */ '../views/product/card/Images')
                    },
                    {
                        path: 'properties',
                        name:'product-properties',
                        component: () => import(/* webpackChunkName: "products" */ '../views/product/card/Properties')
                    },
                    {
                        path: 'categories',
                        name:'product-categories',
                        component: () => import(/* webpackChunkName: "products" */ '../views/product/card/Parents')
                    },
                ]
            },


            {
                path: 'properties',
                name: 'products-properties',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/property/Main'),
                props: true,
            },
            {
                path: 'property/edit',
                name: 'products-property-edit',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/property/Form'),
                props: true,
            },


            {
                path: 'collections',
                name: 'products-collections',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/collection/Main'),
            },
            {
                path: 'collection/edit/:product_property_collection_id',
                name: 'products-collection-edit',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/collection/Form'),
                props: true
            },
            {
                path: 'collection/create',
                name: 'products-collection-create',
                component: () => import(/* webpackChunkName: "products" */ '../views/product/collection/Form')
            }

        ]
    }
]);
