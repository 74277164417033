/**
 * Add ru support
 * @see https://github.com/yiminghe/async-validator
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import Schema from 'async-validator';
import {messages as defaultMessages} from 'async-validator/es/messages';

Schema.prototype.messages = () => ({
    ...defaultMessages,
    required: 'Необходимо заполнить %s',
    types: {
        email: 'Не является правильным %s',
    },
});