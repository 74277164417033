<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
export default {
    name: "AppFooter",
    data() {
        return {
            version: `${process.env.VUE_APP_BUILD_VERSION}/${process.env.VUE_APP_BUILD_TIME}`
        }
    }
}
</script>


<template>
    <el-footer height="30px">
        <el-row>
            <el-col :span="12">
                <p class="buildtime">Сборка: {{ version }}</p>
            </el-col>
            <el-col :span="12">
                <p class="credentials">Разработано <code>ООО "СайтСпринг"</code>. Техническая поддержка <a
                    href="mailto:support@sitespring.ru">support@sitespring.ru</a>
                </p>
            </el-col>
        </el-row>
        <el-backtop></el-backtop>
    </el-footer>
</template>


<style lang="scss" scoped>
.credentials, .buildtime {
    font-size: 12px;
    color: gray;
    margin: 0;
}

.credentials {
    text-align: right;
}
</style>