<script>
    import MainHeader from "./AppHeader";
    import MainFooter from "./AppFooter";

    export default {
        name: 'Main',

        components: {
            MainHeader,
            MainFooter
        }
    }
</script>


<template>
    <el-container id="viewport" direction="vertical">
        <main-header></main-header>
        <el-container>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <main-footer></main-footer>
    </el-container>
</template>


<style scoped lang="scss">
</style>