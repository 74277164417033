import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Main";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },

    /* Default redirect */
    {path: '*', redirect: {name: 'home'}}
];

const router = new VueRouter({
    routes
});

export default router
