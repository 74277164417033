<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import User from "./User";
    import isEmpty from "lodash/isEmpty";

    export default {
        name: "Login",

        data() {
            return {
                model: User
            }
        },


        methods: {
            onLoginClick() {
                this.model.validate()
                    .then((errors, r) => {
                        if (isEmpty(errors)) {

                            User.authByCredentials()
                                .then(() => {
                                    this.$notify.success("Успешный вход");
                                    this.$router.push('/');
                                })
                                .catch((e) => {
                                  console.log(e);
                                    this.$notify.error("Ошибка авторизации");
                                });
                        }
                    });
            }
        }
    }
</script>


<template>
    <el-dialog class="form-container"
               append-to-body
               :show-close="false"
               :center="true"
               :append-to-body="true"
               width="320px"
               title="Требуется авторизация"
               visible>
        <el-form>
            <el-form-item label="Логин"
                          required
                          :error="model.errors.username"
                          :show-message="!!model.errors.username">
                <el-input :maxlength="32"
                          clearable
                          v-model="model.username"></el-input>
            </el-form-item>
            <el-form-item label="Пароль"
                          required
                          :error="model.errors.password"
                          :show-message="!!model.errors.password">
                <el-input :maxlength="32"
                          show-password
                          clearable
                          v-model="model.password"></el-input>
            </el-form-item>
            <el-button :loading="model.isLoading"
                       type="primary"
                       @click="onLoginClick">Вперед
            </el-button>
        </el-form>
    </el-dialog>
</template>


<style lang="scss" scoped>
    /*.form-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100vw;
        height: 100vh;
    }*/

    .el-dialog {
        max-width: 350px!important;
        /*box-shadow: 0 0 5px silver;*/
        padding: 20px;
    }
</style>