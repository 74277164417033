/**
 * Represent User model
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Vue from "vue";
import {Model} from "vue-mc";
import {required, string} from "vue-mc/validation";
import axios from "axios";
import isEmpty from "lodash/isEmpty";


class User extends Model {

    constructor(data, coll, opts) {
        super(data, coll, opts);

        this.isLoading = false;

        this._isAuthorized = false;

        if (!isEmpty(this.auth_token)) {
            this.setupAccessToken(this.auth_token);
        }
    }


    options() {
        return {
            useFirstErrorOnly: true
        }
    }


    defaults() {
        return {
            id: null,
            "username": "",
            "password": "",
            "fullname": "",
            "email": "",
            "phone": "",
            "statusText": "",
            "auth_token": null,
            updated_at: null
        }
    }


    validation() {
        return {
            username: required.and(string).format("Необходимо заполнить"),
            password: required.and(string).format("Необходимо заполнить")
        }
    }


    isAuth() {
        return !!this._isAuthorized;
    };


    setupAccessToken = function (token) {
        if (!isEmpty(token)) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    };


    /**
     * send request to auth by login and password
     * */
    authByCredentials() {
        if (this.isLoading) {
            return;
        }

        Vue.set(this, "isLoading", true);

        return axios.post("user/login", {
            username: this.username,
            password: this.password
        })
            .then(this.onLoginSuccess)
            .finally(() => {
                Vue.set(this, "isLoading", false);
            });
    }


    /**
     * Send auth request by stored token
     * */
    authByToken() {
        if (this.isLoading) {
            return;
        }

        Vue.set(this, "isLoading", true);

        return axios.post("user/login-by-token", {
            token: this.auth_token
        })
            .then(this.onLoginSuccess)
            .finally(() => {
                Vue.set(this, "isLoading", false);
            });
    }


    /**
     * Send login request
     * */
    tryAutoLogin() {
        if (!isEmpty(this.auth_token)) {
            return this.authByToken();
        }
        return new Promise((resolve, reject) => reject("No token"));
    }


    /**
     * Send logout request
     * */
    logout() {
        return this.deleteSession(this.id)
            .finally(this.onLogoutSuccess);
    }


    /**
     * When login request was success
     * */
    onLoginSuccess(resp) {
        this.set(resp.data);

        this.setupAccessToken(resp.data.auth_token);
        this._isAuthorized = true;
        localStorage.setItem("identity", JSON.stringify(resp.data));
    }


    /**
     * When logout request was success
     * */
    onLogoutSuccess(resp) {
        this.clear();
        this.setupAccessToken(null);
        this._isAuthorized = false;
        localStorage.removeItem("identity");
    }


    fetchSessions() {
        return axios.get("user/owner/session").then(response => response.data);
    }


    deleteSession(id) {
        if (this.isLoading) {
            return;
        }

        Vue.set(this, "isLoading", true);
        return axios.delete(`user/owner/session/${id}`)
            .finally(() => {
                Vue.set(this, "isLoading", false);
            });
    }
}


let storedData = localStorage.getItem("identity");
if (!isEmpty(storedData)) {
    storedData = JSON.parse(storedData);
}

// singleton instance
export default new User(storedData);