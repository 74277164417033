<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import User from "./User";

export default {
  name: "SessionList",
  data() {
    return {
      store: []
    }
  },
  computed: {
    filteredStore: vm => vm.store.filter(i => i.id !== User.id)
  },
  methods: {
    fetchStore() {
      User.fetchSessions().then(data => this.store = data);
    },
    onRemoveClick(item) {
      this.$confirm(`Данные авторизации для ${item.app_id} будут удалены. Продолжить?`)
          .then((a) => {
            User.deleteSession(item.id).then(() => {
              this.fetchStore();
            });
          });
    }
  },
  mounted() {
    this.fetchStore();
  }
}
</script>


<template>
  <div class="list">
    <header v-if="filteredStore.length" class="list__header">Другие сессии</header>
    <p v-else class="list__empty-text">Нет других сессий</p>
    <div class="list__item item layout" v-for="item in filteredStore">
      <div class="layout__content">
        <p class="item__app">{{ item.app_id }}</p>
        <p class="item__useragent">{{ item.useragent }}</p>
        <p class="item__updated">{{ $Formatter.asDateTime(item.updated_at) }}</p>
      </div>
      <div class="layout__triggers">
        <el-icon class="el-icon-close trigger-close"
                 @click.native="onRemoveClick(item)"></el-icon>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "src/scss/vars";

.list {

  &__header, &__empty-text {
    font: .9rem/1.5 $font-family-base;
    color: silver;
  }
}

.item {
  //border-top: 1px solid silver;
  margin-top: .5rem;
  padding-top: .5rem;

  p {
    margin: 0;
  }

  &__app {
    font: bold .8rem/1.1 $font-family-base;
  }

  &__useragent {
    opacity: .8;
    font: italic .8rem/1.2 $font-family-base;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__updated {
    font: .8rem/1.5 $font-family-base;
  }
}

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__content {
    flex: 0 0 90%;
    overflow: hidden;
  }

  &__triggers {
    flex: 0 0 32px;
    text-align: center;
    font-size: 24px;

    .trigger-close {
      color: red;
    }
  }
}
</style>