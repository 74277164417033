/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import {MenuItems} from "../views/AppHeader";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-school',
    title: 'Цеха',
    to: {name: 'vendor-list'}
});

router.addRoutes([
    {
        path: '/vendor',
        component: () => import(/* webpackChunkName: "vendor" */ '../views/vendor/Main'),
        children: [
            {
                path: 'list',
                name: 'vendor-list',
                component: () => import(/* webpackChunkName: "vendor" */ '../views/vendor/List'),
            },
            {
                path: 'create',
                name: 'vendor-create',
                component: () => import(/* webpackChunkName: "vendor" */ '../views/vendor/Form')
            },
            {
                path: 'edit/:id',
                name: 'vendor-edit',
                component: () => import(/* webpackChunkName: "vendor" */ '../views/vendor/Form'),
                props: true,
            }
        ]
    }
]);
