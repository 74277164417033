/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import {MenuItems} from "../views/AppHeader";
import orderCalendar from "./orderCalendar";

// add to main menu
MenuItems.push({
    iconCls: 'el-icon-box',
    title: 'Заказы',
    to: {name: 'order-list'}
});

router.addRoutes([
    {
        path: '/order',
        component: () => import(/* webpackChunkName: "orders" */ '../views/order/Main'),
        children: [
            {
                path: 'list',
                name: 'order-list',
                component: () => import(/* webpackChunkName: "orders" */ '../views/order/List'),
            },
            ...orderCalendar,
            {
                path: 'create',
                name: 'order-create',
                component: () => import(/* webpackChunkName: "orders" */ '../views/order/form/Form'),
            },
            {
                path: ':id',
                props: true,
                component: () => import(/* webpackChunkName: "orders" */ '../views/order/card/Main'),
                children: [
                    {
                        path: '',
                        name: 'order-basic',
                        component: () => import(/* webpackChunkName: "orders" */ '../views/order/card/Basic'),
                    },
                    {
                        path: 'balance',
                        name: 'order-balance',
                        component: () => import(/* webpackChunkName: "orders" */ '../views/order/card/Balance'),
                    },
                    {
                        path: 'log',
                        name: 'order-log',
                        component: () => import(/* webpackChunkName: "orders" */ '../views/order/card/Log'),
                    },
                ]
            }
        ]
    }
]);
